<template>
  <div class="about bg-white">
    <!-- Breadcrumb -->
    <vs-breadcrumb
    class="mt-3 container"
    color="#3ECCAA"
    :items="
       [
         {
           title: 'Home',
           url: '/'
         },
         {
           title: 'Kebijakan Privasi',
           active: true
         }
       ]"
    ></vs-breadcrumb>

    <!-- Ubah Bahasa -->
    <vs-row vs-type="flex" vs-justify="flex-end" vs-align="end" class="container">
      <vs-col vs-type="flex" vs-justify="end" vs-align="end" vs-xs="12" vs-md="4" vs-lg="6">
        <div class="text-left container font-mukta font-black700">
          <vs-row vs-w="12" vs-justify="flex-end">
            <vs-col v-if="select1 == 0" vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">Bahasa :</vs-col>
            <vs-col v-else vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">Language :</vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
              <vs-select
                class="selectLang"
                v-model="select1">
                  <vs-select-item v-for="a in options1" :key="a.id" :value="a.value" :text="a.text" />
              </vs-select>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
    </vs-row>

    <!-- Section Privacy Policy -->
    <section class="container text-left mb font-mukta">
      <!-- ID -->
      <div v-if="select1 == 0">
        <h3 style="text-align: center;font-size:14px;"><strong><span style="font-size: 24px;">Kebijakan Privasi</span></strong></h3>

        <h4 style="text-align: center;font-size:14px;"><strong><span style="font-size: 18px;">Tanggal Efektif : 31 Mei 2025</span></strong></h4>

        <p style="text-align: left; font-size: 14px;">
          <br>
        </p>

        <p>PT Someah Kreatif Nusantara (&quot;kami&quot;) mengoperasikan situs web dan aplikasi seluler Islamic Times.</p>

        <p>Kami menggunakan data Anda dengan tujuan untuk menyediakan dan memberikan Layanan aplikasi yang optimal. Dengan menggunakan Layanan kami, Anda telah menyetujui pengumpulan dan penggunaan informasi diri Anda sesuai dengan Kebijakan Privasi dan Ketentuan yang kami terapkan.</p>

        <p>Pengumpulan dan Penggunaan Informasi</p>

        <p>Kami mengumpulkan beberapa jenis informasi yang berbeda untuk berbagai tujuan untuk menyediakan dan meningkatkan Layanan kami kepada Anda.</p>

        <p>
          <br>
        </p>

        <p><strong>Jenis Data Yang Dimpulkan</strong></p>

        <p><strong>1. Data Pribadi</strong></p>

        <p>Saat menggunakan Layanan kami, kami dapat meminta Anda untuk memberikan kami informasi identitas pribadi tertentu yang dapat digunakan untuk menghubungi atau mengidentifikasi Anda (&quot;Data Pribadi&quot;). Informasi identitas pribadi dapat mencakup, tetapi tidak terbatas pada:</p>

        <p>a. Alamat, Negara Bagian, Provinsi, Kode Pos, Kota / Lokasi Anda.</p>

        <p>b. Cookie dan Data Penggunaan.</p>

        <p>
          <br>
        </p>

        <p><strong>2. Data Penggunaan</strong></p>

        <p>Kami juga dapat mengumpulkan informasi yang dikirimkan browser Anda setiap kali Anda mengunjungi Layanan kami atau saat Anda mengakses Layanan oleh atau melalui perangkat seluler (&quot;Data Penggunaan&quot;).</p>

        <p>Data Penggunaan ini dapat mencakup informasi seperti alamat Protokol Internet komputer Anda (misalnya alamat IP), jenis peramban, versi peramban, laman Layanan kami yang Anda kunjungi, waktu dan tanggal kunjungan Anda, waktu yang dihabiskan untuk laman tersebut, unik pengidentifikasi perangkat dan data diagnostik lainnya.</p>

        <p>Saat Anda mengakses Layanan oleh atau melalui perangkat seluler, Data Penggunaan ini dapat mencakup informasi seperti jenis perangkat seluler yang Anda gunakan, ID unik perangkat seluler Anda, alamat IP perangkat seluler Anda, sistem operasi seluler Anda, jenis peramban Internet seluler yang Anda gunakan, pengenal perangkat unik, dan data diagnostik lainnya.</p>

        <p>
          <br>
        </p>

        <p><strong>3. Data Pelacakan &amp; Cookie</strong></p>

        <p>Kami menggunakan cookie dan teknologi pelacakan serupa untuk melacak aktivitas di Layanan kami dan menyimpan informasi tertentu.</p>

        <p>Cookie adalah file dengan sejumlah kecil data yang mungkin termasuk pengidentifikasi unik anonim. Cookie dikirim ke browser Anda dari situs web dan disimpan di perangkat Anda. Teknologi pelacakan juga digunakan adalah beacon, tag, dan skrip untuk mengumpulkan dan melacak informasi dan untuk meningkatkan dan menganalisis Layanan kami.</p>

        <p>Anda dapat menginstruksikan browser Anda untuk menolak semua cookie atau untuk menunjukkan saat cookie sedang dikirim. Namun, jika Anda tidak menerima cookie, Anda mungkin tidak dapat menggunakan beberapa bagian dari Layanan kami.</p>

        <p>Contoh Cookie yang kami gunakan:</p>

        <p>a. Cookie Sesi. Kami menggunakan Cookie Sesi untuk mengoperasikan Layanan kami.</p>

        <p>b. Cookies Preferensi. Kami menggunakan Cookies Preferensi untuk mengingat preferensi Anda dan berbagai pengaturan.</p>

        <p>c. Cookie Keamanan. Kami menggunakan Cookie Keamanan untuk tujuan keamanan.</p>

        <p>
          <br>
        </p>

        <p><strong>4. Penggunaan Data</strong></p>

        <p>Kami menggunakan data yang dikumpulkan untuk berbagai tujuan:</p>

        <p>a. Untuk menyediakan dan memelihara Layanan.</p>

        <p>b. Untuk memberitahukan Anda tentang perubahan di dalam pelayanan kami.</p>

        <p>c. Untuk memungkinkan Anda berpartisipasi dalam fitur interaktif dari Layanan kami ketika Anda memilih untuk melakukannya.</p>

        <p>d. Untuk memberikan layanan dan dukungan pelanggan.</p>

        <p>e. Untuk memberikan analisis atau informasi berharga agar kami dapat meningkatkan Layanan</p>

        <p>f. Untuk memantau penggunaan Layanan</p>

        <p>g. Untuk mendeteksi, mencegah, dan mengatasi masalah teknis</p>

        <p>
          <br>
        </p>

        <p><strong>5. Transfer Data</strong></p>

        <p>Informasi Anda, termasuk Data Pribadi, dapat ditransfer ke - dan dipelihara di - komputer yang terletak di luar negara bagian Anda, provinsi, negara atau yurisdiksi pemerintah lainnya di mana undang-undang perlindungan data dapat berbeda dari yang berasal dari yurisdiksi Anda.</p>

        <p>Jika Anda berada di luar Indonesia dan memilih untuk memberikan informasi kepada kami, harap dicatat bahwa kami mentransfer data, termasuk Data Pribadi, ke Indonesia dan memprosesnya di sana.</p>

        <p>Persetujuan Anda terhadap Kebijakan Privasi ini diikuti dengan penyerahan atas informasi tersebut merupakan persetujuan Anda untuk transfer itu.</p>

        <p>Kami akan mengambil semua langkah yang diperlukan secara wajar untuk memastikan bahwa data Anda diperlakukan dengan aman dan sesuai dengan Kebijakan Privasi. Kami tidak akan mengalihkan Data Pribadi Anda ke organisasi atau negara kecuali ada kontrol yang memadai serta keamanan atas data dan informasi pribadi lainnya.</p>

        <p>
          <br>
        </p>

        <p><strong>Pengungkapan Data</strong></p>

        <p><strong>1. Persyaratan Hukum</strong></p>

        <p>PT Someah Kreatif Nusantara dapat mengungkapkan Data Pribadi Anda dengan iktikad baik bahwa tindakan tersebut diperlukan untuk:</p>

        <p>a. Untuk mematuhi kewajiban hukum.</p>

        <p>b. Untuk melindungi dan membela hak atau milik PT Someah Kreatif Nusantara.</p>

        <p>c. Untuk mencegah atau menyelidiki kemungkinan kesalahan sehubungan dengan Layanan.</p>

        <p>d. Untuk melindungi keamanan pribadi pengguna Layanan atau public.</p>

        <p>e. Untuk melindungi terhadap tanggung jawab hukum.</p>

        <p>
          <br>
        </p>

        <p><strong>2. Keamanan Data</strong></p>

        <p>Keamanan data Anda penting bagi kami, tetapi ingat bahwa tidak ada metode transmisi melalui Internet, atau metode penyimpanan elektronik yang memiliki kemanan 100%. Meskipun kami berusaha menggunakan sarana yang dapat diterima secara komersial untuk melindungi Data Pribadi Anda, kami tidak dapat menjamin keamanan secara mutlak.</p>

        <p>
          <br>
        </p>

        <p><strong>3. Penyedia Layanan</strong></p>

        <p>Kami dapat mempekerjakan perusahaan dan individu pihak ketiga untuk memfasilitasi Layanan kami (&quot;Penyedia Layanan&quot;), untuk menyediakan Layanan atas nama kami, untuk melakukan layanan terkait Layanan atau untuk membantu kami dalam menganalisis bagaimana Layanan kami digunakan.</p>

        <p>Pihak ketiga ini memiliki akses ke Data Pribadi Anda hanya untuk melakukan tugas ini atas nama kami dan berkewajiban untuk tidak mengungkapkan atau menggunakannya untuk tujuan lain.</p>

        <p>
          <br>
        </p>

        <p><strong>Analytics</strong></p>

        <p>Kami dapat menggunakan Penyedia Layanan pihak ketiga untuk memantau dan menganalisis penggunaan Layanan kami.</p>

        <p><strong>Google Analytics</strong></p>

        <p>Google Analytics adalah layanan analisis web yang ditawarkan oleh Google yang melacak dan melaporkan lalu lintas situs web. Google menggunakan data yang dikumpulkan untuk melacak dan memantau penggunaan Layanan kami. Data ini dibagikan dengan layanan Google lainnya. Google dapat menggunakan data yang dikumpulkan untuk mengontekstualisasikan dan mempersonalisasi iklan dari jaringan periklanannya sendiri.</p>

        <p>Untuk informasi lebih lanjut tentang praktik privasi Google, kunjungi halaman web Privasi Google &amp; Ketentuan: <a href="https://policies.google.com/privacy?hl=id">https://policies.google.com/privacy?hl=id</a></p>

        <p><strong>Tautan ke Situs Lain</strong></p>

        <p>Layanan kami mungkin berisi tautan ke situs lain yang tidak dioperasikan oleh kami. Jika Anda mengklik tautan pihak ketiga, Anda akan diarahkan ke situs pihak ketiga tersebut. Kami sangat menyarankan Anda untuk meninjau Kebijakan Privasi dari setiap situs yang Anda kunjungi.</p>

        <p>Kami tidak memiliki kendali atas dan tidak bertanggung jawab atas konten, kebijakan privasi, atau praktik dari situs atau layanan pihak ketiga mana pun.</p>

        <p><strong>Privasi Anak-Anak</strong></p>

        <p>Layanan kami tidak ditujukan kepada siapa pun yang berusia di bawah 15 tahun (&quot;Anak-Anak&quot;).</p>

        <p>Kami tidak dengan sengaja mengumpulkan informasi identitas pribadi dari siapa pun yang berusia di bawah 15 tahun. Jika Anda adalah orang tua atau wali dan Anda sadar bahwa Anak-anak Anda telah memberi kami Data Pribadi, silakan hubungi kami. Jika kami mengetahui bahwa kami telah mengumpulkan Data Pribadi dari anak-anak tanpa verifikasi izin orang tua, kami mengambil langkah-langkah untuk menghapus informasi tersebut dari server kami.</p>

        <p><strong>Perubahan Atas Kebijakan Privasi Ini</strong></p>

        <p>Kami dapat memperbarui Kebijakan Privasi kami dari waktu ke waktu. Kami akan memberi tahu Anda tentang perubahan apa pun dengan memposting Kebijakan Privasi baru di halaman ini. Kami akan memberi tahu Anda melalui email dan / atau pemberitahuan yang menonjol di Layanan kami, sebelum perubahan menjadi efektif dan memperbarui &quot;tanggal efektif&quot; di bagian atas Kebijakan Privasi ini. Anda disarankan untuk meninjau Kebijakan Privasi ini secara berkala untuk setiap perubahan. Perubahan pada Kebijakan Privasi ini efektif ketika mereka diposting di halaman ini.</p>

        <p>
          <br>
        </p>

        <p><strong>Hubungi kami</strong></p>

        <p>Jika Anda memiliki pertanyaan tentang Kebijakan Privasi ini, silakan hubungi kami:</p>

        <p>Melalui email: info@someah.id</p>

        <p>
          <br>
        </p>
      </div>

      <!-- EN -->
      <div v-else>
        <h3 style="text-align: center;font-size:14px;"><strong><span style="font-size: 24px;">Privacy Policy</span></strong></h3>

        <h4 style="text-align: center;font-size:14px;"><strong><span style="font-size: 18px;">Effective Date : May 31, 2025</span></strong></h4>

        <p style="text-align: left; font-size: 14px;">
          <br>
        </p>

        <p>PT Someah Kreatif Nusantara (&quot;we&quot;, &quot;us&quot;, &quot;our&quot;) operates the Islamic Times website and mobile application. We use your data to provide optimal application and other services. By using our Services, you have agreed that we can collect and use of your personal information under the Privacy Policy and Terms that we apply.</p>

        <p>We collect your several different types of information for various purposes, but the main purpose is to provide and improve our Services to you.</p>

        <p>
          <br>
        </p>

        <p><strong>Types of Data Collected</strong></p>

        <p><strong>1. Personal Data</strong></p>

        <p>When using our Services, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (&ldquo;Personal Data&rdquo;). Personally identifiable information can include, but is not limited to:</p>

        <p>a. Address, State, Province, Zip Code, City / Your Location.</p>

        <p>b. Cookies and Usage Data.</p>

        <p>
          <br>
        </p>

        <p><strong>2. Usage Data</strong></p>

        <p>We may also collect information that your browser sends whenever you visit our Services or when you access the Service by or via a mobile device (&quot;Usage Data&quot;).</p>

        <p>This Usage Data may include information such as your computer&#39;s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Services that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.&nbsp;</p>

        <p>When you access our Services by or via a mobile device, this Usage Data may include information such as the type of mobile device that you use, the unique ID of your mobile device, the IP address of your mobile device, your mobile operating system, the type of mobile browser you use, an identifier unique devices, and other diagnostic data.</p>

        <p>
          <br>
        </p>

        <p><strong>3. Tracking Data &amp; Cookies</strong></p>

        <p>We use cookies and similar tracking technologies to track your activity on our Services and store the certain information to our database.&nbsp;</p>

        <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags and scripts to collect and track information and to improve and analyze our Services.</p>

        <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags and scripts to collect and track information and to improve and analyze our Services.</p>

        <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some parts of our Service.</p>

        <p>Cookies that we use:</p>

        <p>a. Session Cookies. We use Session Cookies to operate our Service.</p>

        <p>b. Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</p>

        <p>c. Security Cookies. We use Security Cookies for security purposes.</p>

        <p>
          <br>
        </p>

        <p><strong>4. Data Usage</strong></p>

        <p>We use collected data for various purposes:</p>

        <p>a. To provide and maintain the Service.</p>

        <p>b. To notify you about changes to our service.</p>

        <p>c. To allow you to participate in interactive features of our Services when you choose to do so.</p>

        <p>d. To provide customer service and support.</p>

        <p>e. To provide analysis or valuable information so that we can improve the Services.</p>

        <p>f. To monitor usage of the Servicea.</p>

        <p>g. To detect, prevent and resolve technical problems.</p>

        <p>
          <br>
        </p>

        <p><strong>5. Data Transfer</strong></p>

        <p>Your information, including Personal Data, may be transferred to - and maintained on - computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>

        <p>If you are located outside Indonesia and choose to provide use your information, please note that we transfer data, including your Personal Data, to Indonesia and process the data here.</p>

        <p>Your submission of the information that you provide to us is your consent to the existing of The Privacy Policy.</p>

        <p>We will take all reasonable steps necessary to ensure that your data is treated safely and under the Privacy Policy. We will not transfer your Personal Data to organizations or countries unless there is adequate control and security over other personal data and information.</p>

        <p>
          <br>
        </p>

        <p><strong>Disclosure of Data</strong></p>

        <p><strong>1. Legal Requirements</strong></p>

        <p>PT Someah Kreatif Nusantara may disclose your Personal Data in good belief and make sure that such action is necessary to:</p>

        <p>a. To comply with a legal obligation.</p>

        <p>b. To protect and defend the rights or property of PT Someah Kreatif Nusantara.</p>

        <p>c. To prevent or investigate possible errors in connection with the Services.</p>

        <p>d. To protect the personal safety of our users or the public.</p>

        <p>e. To protect against legal liability.</p>

        <p>
          <br>
        </p>

        <p><strong>2. Data Security</strong></p>

        <p>The security of your data is important to us, but remember that there is no method of transmission over the Internet, or method of electronic storage is 100% secure. Although we try to use commercially acceptable methods to protect your Personal Data, we cannot guarantee absolute security.</p>

        <p>
          <br>
        </p>

        <p><strong>3. Service Providers</strong></p>

        <p>We may employ third party companies and individuals to facilitate our Services (&quot;Service Providers&quot;), to provide the Service on our behalf, in purpose to perform Service-related services or to assist us in analyzing how our Service is used.</p>

        <p>These third parties have access to your Personal Data to perform this task on our behalf and are obliged not to disclose or use it for any other purpose.</p>

        <p>
          <br>
        </p>

        <p><strong>4. Service Providers</strong></p>

        <p>We may employ third party companies and individuals to facilitate our Services (&quot;Service Providers&quot;), to provide Services on our behalf, to perform Service-related services or to assist us in analyzing how our Services are used.</p>

        <p>These third parties have access to your Personal Data only to perform the task given on our behalf and are obliged not to disclose or use it for any other purpose.</p>

        <p>
          <br>
        </p>

        <p><strong>Analytics</strong></p>

        <p>We may use third party Service Providers to monitor and analyze the use of our Services.</p>

        <p><strong>Google Analytics</strong></p>

        <p>Google Analytics is a web analysis service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor use of our Services. This data is shared with other Google services. Google may use the data collected to contextualize and personalize advertisements from its own advertising network.</p>

        <p>For more information about Google&#39;s privacy practices, visit the Google Privacy &amp; Terms web page: https://policies.google.com/privacy?hl=en</p>

        <p><strong>Links to Other Sites</strong></p>

        <p>Our services may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party&#39;s site. We strongly encourage you to review the Privacy Policy of each site you visit.</p>

        <p>We have no control over and are not responsible for the content, privacy policies or practices of any third party sites or services.</p>

        <p><strong>Children&#39;s Privacy</strong></p>

        <p>Our services are not conducted to anyone under 15 years of age.</p>

        <p>We do not knowingly collect personally identifiable information from anyone under the age of 15. If you are a parent or guardian and you are aware that your Children have provided us with Personal Data, please contact us. If we have collected Personal Data from children without verification of parental consent, we will take steps to delete that information from our servers.</p>

        <p><strong>Changes of Our Privacy Policy</strong></p>

        <p>We may update our Privacy Policy from time to time. But we will notify you of any changes by posting a new Privacy Policy on this page.</p>

        <p>We will notify you by email or a noticeable notification on our Service, before the change becomes effective and updates the &quot;effective date&quot; at the top of this Privacy Policy. We recommend you to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

        <p>
          <br>
        </p>

        <p><strong>Contact Us</strong></p>

        <p>If you have any questions about this Privacy Policy, please contact us:</p>

        <p>E-mail: &nbsp;info@someah.id</p>

        <p>
          <br>
        </p>

      </div>
    </section>

    <!-- Footer -->
    <footer class="footer">
      <vs-row class="container pt-5" vs-justify="left" vs-align="start">
        <vs-col vs-type="flex" vs-justify="center" vs-align="start" vs-lg="3" vs-sm="6" vs-xs="12">
          <div class="secinfo">
            <img src="../assets/images/IslamicTimes.svg" id="imgfooter" alt="footerIslamicTimes">
            <p>
              <a id="linkinfo" style="yellow" href="#">info@someah.id</a>
            </p>
            <p id="linkwa">
              <a href="#">+628562294222</a>
            </p>
            <a id="iconig" href="#"><img src="../assets/images/uil_instagram-alt.svg" alt="iconinstagram"></a>
            <a id="iconlin" href="#"><img src="../assets/images/carbon_logo-linkedin.svg" alt="iconlinkedlin"></a>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="6" vs-xs="12">
          <div id="secperu">
            <h5 class="jdl">Tentang Kami</h5>
            <a href="https://someah.id/" target="_blank" class="f-content">Someah Kreatif Nusantara</a><br>
            <a href="#" class="f-content">Dewan Hisab dan Rukyat Persis</a>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="6" vs-xs="12">
          <div id="secisl">
            <h5 class="jdl1">Islamic</h5>
            <a href="#" class="f-content">Fitur</a><br>
            <a href="#" class="f-content">Panduan</a><br>
            <a href="" class="f-content">FAQ</a>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="6" vs-xs="12">
          <div id="sectersedia">
            <h5 class="jdl2">Tersedia Di</h5>
            <a href="http://bit.ly/islamic-times" target="_blank"><img src="../assets/images/LogoPlaystore.svg" id="iconplay" alt="LogoPlayStore"></a><br>
          </div>
        </vs-col>
      </vs-row>
      <div class="akhiran" vs-lg="12" vs-sm="6" vs-xs="12">
        <hr class="linefooter">
        <p class="txtfooter">© 2020 PT Someah Kreatif Nusantara. Hak Cipta dilindungi oleh Undang-Undang | <a class="text-bluelight" href="/privacy-policy" @click.prevent="halPrivasi()">Kebijakan Privasi</a></p>
      </div>
    </footer>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        select1: 0,
        options1: [
          {
            text: 'ID',
            value: 0
          },
          {
            text: 'EN',
            value: 1
          },
        ],
      }
    },
    methods: {
      halPrivasi() {
        this.$router.push('/privacy-policy')
        window.scrollTo({
          top: 0,
        })
      },
    },
  }
</script>

<style lang="scss">
  /**
   * Container Responsive
   */
  @media (min-width: 276px) {
    .container {
      padding: 0px 30px;
    }
  }

  @media (min-width: 576px) {
    .container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }

  .pt-5 {
    padding-top: 5rem;
  }
</style>
